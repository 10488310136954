"use client";
import React, { useRef, useState } from "react";
import "./style.css";
import { useOpenContext } from "../../Context/store";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Subscribe() {
  const [isShowing, setIsShowing] = useState(false);

  const [name, setName] = useState("");
  const [email] = useState("contato@meile.com.br");
  const [message, setMessage] = useState("");

  const { open, setOpen } = useOpenContext();

  const toggle = () => {
    setIsShowing(!isShowing);
    setOpen(!open);
    localStorage.setItem("popup", "true");
  };

  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    if (!message || !name) {
      toast.warn("favor preencher o campo de E-mail", { theme: "colored" });
      return;
    }
    await emailjs
      .sendForm(
        "service_1tzkx6s",
        "template_4dfw45y",
        form.current,
        "veLrXn4jJ8yPUpsW_"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("E-mail cadastrado com sucesso!", { theme: "colored" });
          setMessage("");
          toggle();
        },
        (error) => {
          console.log(error.text);
          toast.error("erro ao cadastrar E-mail, tente novamente!", {
            theme: "colored",
          });
        }
      );
  };

  return (
    <>
      <ToastContainer />
      <div
        className="subscribe"
        onClick={toggle}
        style={{
          display: isShowing ? "flex" : "none",
          display: open ? "flex" : "none",
        }}
      ></div>

      <div
        className="subscribe__container"
        style={{
          display: isShowing ? "flex" : "none",
          display: open ? "flex" : "none",
        }}
      >
        <div className="image_subs"></div>
        <div className="form_subscribe">
          <div className="close" onClick={toggle}>
            X
          </div>
          <div className="subscribe_information">
            <div className="subscribe_headline">
              Agenda sua apresentação com um especialista
            </div>
            <div className="subscribe_subheadline">
              Estamos ansiosos para vê-lo brilhar! Agende sua apresentação para
              o Programa Partner e mostre o seu potencial. Entre em contato
              conosco agora mesmo para garantir o seu lugar e dar o primeiro
              passo rumo ao sucesso.
            </div>
          </div>

          <label>digite o seu melhor e-mail</label>

          <form ref={form} onSubmit={sendEmail}>
            <input
            required
              type="text"
              name="user_name"
              value={name}
              placeholder="Digite o seu nome"
              onChange={(e) => setName(e.target.value)}
            />
            <input type="email" name="user_email" value={email} readOnly />
            <input
            required
              type="text"
              name="message"
              placeholder="E-mail"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <input type="submit" value="agendar agora!" />
          </form>
        </div>
      </div>
    </>
  );
}

export default Subscribe;
