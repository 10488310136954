import React from "react";

import "./style.css";
import wpp from "../../assets/img/whatsapp.svg";
function Whatsapp() {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=553132117760&text=Olá,%20quero%20ser%20um%20Partner!%20"
      target="_blank"
      rel="noreferrer"
    >
      <div className="whataspp_container">
        <img src={wpp} alt="social" />
      </div>
    </a>
  );
}

export default Whatsapp;
