import React from "react";

import "./style.css";
import stars from "../../assets/img/stars.svg";
import person from "../../assets/img/p1.png";
import person2 from "../../assets/img/p2.png";
import person3 from "../../assets/img/p3.png";
import Reveal from "../Reveal";

function Step() {
  return (
    <div className="step_wrapper">
      <div className="step_container">
        <Reveal
          delay="0.2"
          component={
            <div className="step_headline">
              Cadastre-se agora em nosso Programa Partner e comece a aproveitar
              os benefícios!
            </div>
          }
        />
        <div className="step_box_container">
          <Reveal
            delay="0.4"
            component={
              <div className="box">
                <div className="img_circle">
                  <img src={person} alt="stars" />
                </div>
                <div className="box_headline">
                  Cadastre-se no portal Partner Meile
                </div>
                <img className="stars" src={stars} alt="stars" />
              </div>
            }
          />
          <Reveal
            delay="0.6"
            component={
              <div className="box">
                <div className="img_circle">
                  <img src={person2} alt="stars" />
                </div>
                <div className="box_headline">Indique</div>
                <img className="stars" src={stars} alt="stars" />
              </div>
            }
          />
          <Reveal
            delay="0.8"
            component={
              <div className="box">
                <div className="img_circle">
                  <img src={person3} alt="stars" />
                </div>
                <div className="box_headline">Ganhe</div>
                <img className="stars" src={stars} alt="stars" />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Step;
