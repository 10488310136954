import React, { useRef, useState } from "react";

import "./style.css";
import logo from "../../assets/img/logodark.svg";
import social from "../../assets/img/Group 4385.svg";
import social2 from "../../assets/img/Group 4386.svg";
import social3 from "../../assets/img/Group 4387.svg";
import mail from "../../assets/img/meil.svg";
import arrow from "../../assets/img/arrowtop.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Footer() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    if (!message || !email) {
      toast.warn("preencha todos os campos!", { theme: "dark" });
      return;
    }
    await emailjs
      .sendForm(
        "service_1tzkx6s",
        "template_4dfw45y",
        form.current,
        "veLrXn4jJ8yPUpsW_"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("mensagem enviada com sucesso!", { theme: "dark" });
          setMessage("");
          setEmail("");
        },
        (error) => {
          console.log(error.text);
          toast.error("erro ao enviar mensagem, tente novamente!", {
            theme: "dark",
          });
        }
      );
  };

  return (
    <div className="footer_wrapper">
      <ToastContainer />

      <div className="Marquee">
        <div className="Marquee-content">
          <div className="Marquee-tag">
            <div className="info_mar">
              <a
                href="https://partner.meile.com.br/partners/cadastro"
                target="_blank"
                rel="noreferrer"
              >
                <button>seja partner</button>Pronto para se mover mais rápido?
              </a>
            </div>
          </div>
          <div className="Marquee-tag">
            <div className="info_mar">
              <a
                href="https://partner.meile.com.br/partners/cadastro"
                target="_blank"
                rel="noreferrer"
              >
                <button>seja partner</button>Pronto para se mover mais rápido?
              </a>
            </div>
          </div>
          <div className="Marquee-tag">
            <div className="info_mar">
              <a
                href="https://partner.meile.com.br/partners/cadastro"
                target="_blank"
                rel="noreferrer"
              >
                <button>seja partner</button>Pronto para se mover mais rápido?
              </a>
            </div>
          </div>

          <div className="Marquee-tag">
            <div className="info_mar">
              <a
                href="https://partner.meile.com.br/partners/cadastro"
                target="_blank"
                rel="noreferrer"
              >
                <button>seja partner</button>Pronto para se mover mais rápido?
              </a>
            </div>
          </div>

          <div className="Marquee-tag">
            <div className="info_mar">
              <a
                href="https://partner.meile.com.br/partners/cadastro"
                target="_blank"
                rel="noreferrer"
              >
                <button>seja partner</button>Pronto para se mover mais rápido?
              </a>
            </div>
          </div>

          <div className="Marquee-tag">
            <div className="info_mar">
              <a
                href="https://partner.meile.com.br/partners/cadastro"
                target="_blank"
                rel="noreferrer"
              >
                <button>seja partner</button>Pronto para se mover mais rápido?
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_container">
        <div className="footer_left">
          <img src={logo} alt="social" />
          <div className="footer_headline">
            Quer receber notícias, promoções, descontos e atualizações?
          </div>

          {/* <form>
            <input placeholder="Name"></input>
            <input placeholder="E-mail"></input>
            <div className="check_container">
              <input type="checkbox"></input>
              Concordo com a <span> declaração de privacidade</span>
            </div>
            <Button title="Receba as últimas atualizações" />
          </form> */}

          <form ref={form} onSubmit={sendEmail} className="form">
            <input
              required
              type="text"
              placeholder="Nome completo"
              name="user_name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              required
              type="email"
              placeholder="email@exemplo.com.br"
              name="user_email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="form__sub">
              <p>
                Adicionaremos suas informações ao nosso CRM para entrar em
                contato com você sobre sua solicitação.
              </p>
              <input type="submit" value="Receba as últimas atualizações" />
            </div>
          </form>
        </div>
        <div className="footer_right">
          <div className="footer_collums">
            <div className="title">Produtos</div>
            <div className="topics"> email </div>
            <div className="topics">box</div>
            <div className="topics">antispam</div>
            <div className="topics">email mkt</div>
            <div className="topics">email smtp</div>
          </div>

          <div className="footer_collums">
            <div className="title">Empresa</div>
            <div className="topics"> blog </div>
            <div className="topics">sobre nós</div>
            <div className="topics">lgpd</div>
          </div>

          <div className="footer_collums">
            <div className="title">Suporte</div>
            <div className="topics"> portal do cliente</div>
            <div className="topics">faq</div>
            <div className="topics">wiki</div>
          </div>

          <div className="footer_collums">
            <div className="title">Endereço</div>
            <div className="topics">
              Rua Boaventura, 1621 - 3º andar Jaraguá Belo Horizonte, MG CEP
              31270-310
            </div>
          </div>

          <div className="footer_collums contact">
            <div className="title">Contato</div>
            <div className="topics">
              <span>Geral : (31) 3211-7777 </span>
              <span>
                <img src={mail} alt="social" /> contato@meile.com.br
              </span>
            </div>
            <div className="topics">
              <span>Financeiro : (31) 3211-7730 </span>
              <span>
                <img src={mail} alt="social" />
                financeiro@meile.com.br
              </span>
            </div>
            <div className="topics">
              <span>Comercial : (31) 3211-7725 </span>
              <span>
                <img src={mail} alt="social" /> comercial@meile.com.br
              </span>
            </div>
          </div>

          <div className="footer_collums social">
            <div className="title">Redes sociais</div>
            <div className="icon_container">
              <div className="topics">
                <a
                  href="https://www.youtube.com/user/MAVTecnologia"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={social} alt="social" />
                </a>
              </div>

              <div className="topics">
                <a
                  href="https://www.linkedin.com/company/mavtecnologiabr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={social2} alt="social" />
                </a>
              </div>
              <div className="topics">
                <a
                  href="https://www.instagram.com/mavtecnologia/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={social3} alt="social" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sub_footer">
        <div className="rights">
          © 2024 ✦ Todos os direitos reservados - Meile
        </div>

        <AnchorLink href="#home">
          <div className="got_to_top">
            <img src={arrow} alt="social" />
          </div>
        </AnchorLink>

        <div className="rights"></div>
      </div>
    </div>
  );
}

export default Footer;
