import React, { useState } from "react";

import "./style.css";
import bimg from "../../assets/img/infoimg.svg";
import Button from "../Button";
import { useOpenContext } from "../../Context/store";
import Reveal from "../Reveal";
function Info() {
  const [isShowing, setIsShowing] = useState(false);
  const { open, setOpen } = useOpenContext();

  const toggle = () => {
    setIsShowing(!isShowing);
    setOpen(!open);
    localStorage.setItem("popup", "true");
  };
  return (
    <div className="info_wrapper">
      <div className="info_container">
        <div className="info_info_left">
          <Reveal
            delay="0.2"
            component={
              <div className="info_headline">
                O <span>Programa Partner da Meile</span> oferece uma
                oportunidade única para expandir suas parcerias e ser{" "}
                <span>recompensado</span>
                por indicar clientes em potencial.
              </div>
            }
          />
          <Reveal
            delay="0.4"
            component={
              <div className="info_subheadline">
                Os Partners Meile podem ganhar até 300% de comissão no primeiro
                ano, 200% no segundo ano e 100% no terceiro ano do contrato do
                produto indicado. Existem três categorias de Partners - Silver,
                Gold e Diamond - com benefícios variados de acordo com a
                categoria do Partner e do contrato do cliente indicado.
              </div>
            }
          />
          <Reveal
            delay="0.6"
            component={
              <div onClick={toggle}>
                <Button title="agende sua apresentação com um especialista" />
              </div>
            }
          />
        </div>
        <div className="info_info_right">
          <Reveal delay="0.8" component={<img src={bimg} alt="info" />} />
        </div>
      </div>
    </div>
  );
}

export default Info;
