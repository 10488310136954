import React from "react";

import "./style.css";
import bimg from "../../assets/img/bimg.svg";
import zig from "../../assets/img/zig.svg";
import Button from "../Button";
import Reveal from "../Reveal";
function Banner() {
  return (
    <div className="banner_wrapper" id="home">
      <div className="banner_container">
        <div className="banner_info_left">
          <Reveal
            delay="0.2"
            component={
              <div className="descripiton_info">Programa Partner da Meile</div>
            }
          />

          <Reveal
            delay="0.4"
            component={
              <div className="banner_headline">
                Seja um <span>Partner</span> e converta indicações em{" "}
                <span>
                  recompensas
                  <img src={zig} alt="banner" />
                </span>
                .
              </div>
            }
          />

          <Reveal
            delay="0.6"
            component={
              <div className="banner_subheadline">
                Com comissões generosas e suporte dedicado, estamos prontos para
                construir uma parceria de sucesso.
              </div>
            }
          />
          <Reveal
            delay="0.8"
            component={
              <a
                href="https://partner.meile.com.br/partners/cadastro"
                target="_blank"
                rel="noreferrer"
              >
                <Button title="seja um Partner" />
              </a>
            }
          />
        </div>
        <div className="banner_info_right">
          <Reveal delay="1" component={<img src={bimg} alt="banner" />} />
        </div>
      </div>
    </div>
  );
}

export default Banner;
