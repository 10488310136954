import React from "react";

import "./style.css";
import box from "../../assets/img/box.svg";
import mail from "../../assets/img/mail.svg";
import antispam from "../../assets/img/antispam.svg";
import vps from "../../assets/img/server-solid 1.svg";
import sm from "../../assets/img/servermail.svg";
import ev from "../../assets/img/mvoic.svg";
import site from "../../assets/img/laptop-solid.svg";
import Reveal from "../Reveal";

function Products() {
  return (
    <div className="products_wrapper">
      <div className="products_container">
        <Reveal
          classe={"box"}
          delay="0.2"
          component={<div className="products_headline">nosso produtos</div>}
        />
        <div className="products_box_container">
          <Reveal
            classe={"box"}
            delay="0.4"
            component={
              <div>
                <div className="img_circle">
                  <img src={mail} alt="products" />
                </div>
                <div className="box_headline">E-mail Corporativo</div>
                <p>Hospedagem completa de e-mail profissional.</p>
              </div>
            }
          />
          <Reveal
            classe={"box"}
            delay="0.6"
            component={
              <div>
                <div className="img_circle">
                  {" "}
                  <img src={site} alt="products" />
                </div>
                <div className="box_headline">Hospedagens de Sites</div>
                <p>
                  Aumente o desempenho do seu site com nossa hospedagem SSD.
                </p>
              </div>
            }
          />
          <Reveal
            classe={"box"}
            delay="0.8"
            component={
              <div>
                <div className="img_circle">
                  <img src={box} alt="products" />
                </div>
                <div className="box_headline">Meile Box</div>
                <p>
                  Compartilhe arquivos, calendários, tarefas e muito mais de
                  forma colaborativa e em nuvem segura.
                </p>
              </div>
            }
          />
          <Reveal
            classe={"box"}
            delay="1"
            component={
              <div>
                <div className="img_circle">
                  <img src={antispam} alt="products" />
                </div>
                <div className="box_headline">Antispam</div>
                <p>
                  Garanta 99,9% de eficácia na proteção contra spams, fraudes e
                  phishing (roubo de informações), realizamos as filtragens dos
                  seus e-mails e entregamos as mensagens ao seu servidor local.
                </p>
              </div>
            }
          />
          <Reveal
            classe={"box"}
            delay="1.2"
            component={
              <div>
                <div className="img_circle">
                  <img src={vps} alt="products" />
                </div>
                <div className="box_headline">VPS</div>
                <p>
                  Aumente o desempenho do seu site com nossa hospedagem SSD.
                </p>
              </div>
            }
          />
          <Reveal
            classe={"box"}
            delay="1.4"
            component={
              <div>
                <div className="img_circle">
                  <img src={sm} alt="products" />
                </div>
                <div className="box_headline">Meile SMTP</div>
                <p>
                  Envie com facilidade todos os seus e-mails importantes, como
                  notas fiscais, boletos, notificações de softwares ERP e
                  outros.
                </p>
              </div>
            }
          />
          <Reveal
            classe={"box"}
            delay="1.6"
            component={
              <div>
                <div className="img_circle">
                  <img src={ev} alt="products" />
                </div>
                <div className="box_headline">E-mail Marketing</div>
                <p>
                  Divulgue, venda mais, engaje e crie fluxos de recuperação de
                  vendas com modelos de e-mails simples de serem personalizados.
                </p>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Products;
