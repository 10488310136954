import "./App.css";
import Banner from "./components/banner";
import Info from "./components/info";
import Step from "./components/step";
import Products from "./components/products";
import Footer from "./components/footer";
import Whatsapp from "./components/whatsapp";
import Menu from "./components/menu";
import Subscribe from "./components/Subscribe";
import { OpenContextProvider } from "./Context/store";

function App() {
  return (
    <div className="App">
      <OpenContextProvider>
        <Menu />
        <Banner />
        <Info />
        <Step />
        <Products />
        <Footer />
        <Whatsapp />
        <Subscribe />
      </OpenContextProvider>
    </div>
  );
}

export default App;
